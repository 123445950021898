<template>
    <div>
        <ts-page-title
            :title="$t('journalEntry.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('journalEntry.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-justify-end tw-space-x-2">
                    <Button type="info" ghost @click="addNew">{{
                        $t('addNew')
                    }}</Button>
                    <Button type="primary" ghost @click="() => showRefreshBalance = true">{{
                        $t('refreshBalance')
                    }}</Button>
                </div>

                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('journalEntry.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="cycle_payment_date">
                    {{
                        row.loan_repayment
                            ? row.loan_repayment.cycle_payment_date
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="tran_id">
                    {{
                        row.transaction_type
                            ? row.transaction_type.tran_name
                            : 'Manual'
                    }}
                </template>
                <template slot-scope="{ row }" slot="currency_id">
                    {{
                        row.currency
                            ? row.currency.currency_name_en +
                              '|' +
                              row.currency.currency_code
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="co">
                    {{
                        row.loan
                            ? row.loan.co
                                ? row.loan.co.employee_name_en
                                : ''
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="customer">
                    {{
                        row.loan
                            ? row.loan.customer
                                ? row.loan.customer.customer_name_en
                                : ''
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        :title="$t('are_you_sure_to_delete')"
                        :transfer="true"
                        width="240"
                        v-if="!row._deleting"
                        @on-popper-show="() => (model.deleted_reason = '')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                        <div slot="content">
                            <label class="required">{{
                                $t('journalEntry.reason')
                            }}</label>
                            <input
                                v-model.trim="model.deleted_reason"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        errors.has('deleted_reason') ||
                                        $v.model.deleted_reason.$error
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                v-if="reasonErrors.length > 0"
                                >{{ reasonErrors[0] }}</span
                            >
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('deleted_reason')"
                            >
                                {{ errors.first('deleted_reason') }}
                            </div>
                            <div class="tw-mt-2 tw-text-right">
                                <a
                                    class="ivu-btn ivu-btn-primary ivu-btn-small"
                                    @click.prevent="onDelete(row)"
                                >
                                    <span>OK</span>
                                </a>
                            </div>
                        </div>
                    </Poptip>
                    <a
                        v-else
                        class="ml-2 text-danger"
                        v-tooltip="$t('delete')"
                        :disabled="row._deleting"
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-trash" size="20" v-else />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('journalEntry.pageTitle')"
                width="90%"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="
                        fetchData({
                            branch_id: branch_id,
                            dateRange: dateRange
                        })
                    "
                />
            </Modal>
            <!-- form-refresh-balance -->
            <Modal
                v-model="showRefreshBalance"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('journalEntry.refreshBalance')"
                width="300"
            >
                <div class="row mb-3">
                    <div class="col-md-12">
                        <label class="form-col-label control-label required">{{
                            $t("journalEntry.branch")
                        }}</label>
                        <ts-branch-filter @filter="value => (refresh_branch_id = value)" :multiple="false"/>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12">
                        <label class="form-col-label control-label required">{{
                            $t("journalEntry.refreshDate")
                        }}</label>
                        <DatePicker
                            type="date"
                            :value="refresh_date"
                            style="width:100%"
                            placeholder="DD-MM-YYYY"
                            format="dd-MM-yyyy"
                            ref="refresh_date"
                            @on-change="onRefreshDate"
                        ></DatePicker>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('refresh_date')"
                        >
                            {{ errors.first("refresh_date") }}
                        </div>
                    </div>
                </div>
                    <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                        <ts-button
                            @click.prevent="() => showRefreshBalance = false"
                            class="btn-gray"
                        >
                            {{ $t("cancel") }}</ts-button
                        >
                        <ts-button
                            color="primary"
                            :waiting="waiting"
                            @click.prevent="onRefresh"
                        >
                            {{ $t("refresh") }}</ts-button
                        >
                    </div>
            </Modal>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('branchName')
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('journalEntry.date')
                }}</label>
                <DatePicker
                    type="daterange"
                    v-model="dateRange"
                    placement="bottom-end"
                    :placeholder="$t('journalEntry.selectDateRange')"
                    style="width: 100%"
                    :transfer="true"
                    format="dd-MM-yyyy"
                    @on-change="onChangeTransactionDate"
                ></DatePicker>
            </div>
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('generalJournalRecord.transactionType')
                }}</label>
                <Select
                    v-model="tran_id"
                    :filterable="true"
                    :filter-by-label="true"
                    :placeholder="$t('all')"
                    :clearable="true"
                >
                    <Option :value="1" :key="1" label="Manual">
                        Manual
                    </Option>
                    <Option
                        v-for="item in transactionTypes"
                        :value="item.tran_id"
                        :key="item.tran_id"
                        :label="item.tran_name"
                    >
                        {{ item.tran_name }}
                    </Option>
                </Select>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
import FromAction from './form'
import expandRow from './table-expand.vue'
import { required } from 'vuelidate/lib/validators'
import { Errors } from 'form-backend-validation'
import moment from 'moment'

export default {
    name: 'journalEntryIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            showRefreshBalance: false,
            visible: false,
            waiting: false,
            branch_id: [],
            refresh_branch_id: null,
            tran_id: null,
            dateRange: [
                moment().format('DD-MM-YYYY'),
                moment().format('DD-MM-YYYY')
            ],
            width: 'width: 300px',
            errors: new Errors(),
            refresh_date: moment().format('DD-MM-YYYY'),
            model: {
                deleted_reason: ''
            }
        }
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ['model.deleted_reason']
    },
    computed: {
        ...mapState('accounting/journalEntry', [
            'resources',
            'pagination',
            'transactionTypes'
        ]),
        search: {
            get () {
                return this.$store.state.accounting.journalEntry.search
            },
            set (newValue) {
                this.$store.commit(
                    'accounting/journalEntry/SET_SEARCH',
                    newValue
                )
                this.$store.commit('accounting/journalEntry/RESET_CURRENT_PAGE')
            }
        },
        reasonErrors () {
            const errors = []
            if (!this.$v.model.deleted_reason.$error) return errors
            !this.$v.model.deleted_reason.required && errors.push('Required')
            return errors
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    width: 30
                },
                {
                    title: this.$t('journalEntry.branch'),
                    slot: 'branch',
                    sortable: true,
                    width: 150
                },
                {
                    title: this.$t('journalEntry.date'),
                    key: 'transaction_date',
                    sortable: true,
                    align: 'center',
                    width: 160
                },
                {
                    title: this.$t('journalEntry.transactionType'),
                    slot: 'tran_id',
                    sortable: true,
                    width: 160
                },
                {
                    title: this.$t('journalEntry.scheduleDate'),
                    slot: 'cycle_payment_date',
                    sortable: true,
                    align: 'center',
                    width: 160
                },
                {
                    title: this.$t('journalEntry.currency'),
                    slot: 'currency_id',
                    sortable: true,
                    width: 150
                },
                {
                    title: this.$t('journalEntry.fxRate'),
                    key: 'fx_rate',
                    width: 90,
                    align: 'center'
                },
                {
                    title: this.$t('journalEntry.referenceNo'),
                    key: 'reference_no',
                    sortable: true,
                    width: 200,
                    align: 'center'
                },
                {
                    title: this.$t('journalEntry.co'),
                    slot: 'co',
                    sortable: true,
                    minWidth: 200
                },
                {
                    title: this.$t('journalEntry.customer'),
                    key: 'customer',
                    slot: 'customer',
                    sortable: true,
                    minWidth: 250
                },
                {
                    title: this.$t('journalEntry.remarks'),
                    key: 'remarks',
                    width: 310
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('accounting/journalEntry', ['getTransactionType']),
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('accounting/journalEntry/fetch', {
                    ...attributes,
                    branch_id: this.branch_id,
                    tran_id: this.tran_id,
                    dateRange: this.dateRange
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        onChangeTransactionDate (dateRange) {
            this.dateRange = dateRange
            this.fetchData({
                branch_id: this.branch_id,
                tran_id: this.tran_id,
                dateRange: this.dateRange
            })
        },
        onEdit (record) {
            this.$refs.form_action.clearInput()
            this.$store.commit('accounting/journalEntry/SET_EDIT_DATA', record)
            this.showForm = true
            this.$refs.form_action.fetchResource()
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput()
            this.$store.commit('accounting/journalEntry/SET_EDIT_DATA', {})
        },
        addNew () {
            this.$store.commit('accounting/journalEntry/SET_EDIT_DATA', {})
            this.showForm = true
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        async onDelete (record) {
            this.$v.form.$touch()
            if (this.$v.form.$invalid) return

            record._deleting = true
            this.$store
                .dispatch('accounting/journalEntry/destroy', {
                    id: record.transaction_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData()
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                    this.model.deleted_reason = ''
                })
        },
        onRefreshDate(date) {
            this.refresh_date = date;
        },
        onRefresh() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("accounting/journalEntry/refresh", {
                    refresh_date: this.refresh_date,
                    refresh_branch_id: this.refresh_branch_id
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.fetchData();
                    this.showRefreshBalance = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'JOURNAL ENTRY',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({
                search,
                branch_id: this.branch_id,
                tran_id: this.tran_id,
                dateRange: this.dateRange
            })
        }, 500),
        branch_id: debounce(function (value) {
            this.fetchData({
                branch_id: value,
                tran_id: this.tran_id,
                dateRange: this.dateRange
            })
        }, 500),
        tran_id: debounce(function (value) {
            this.fetchData({
                tran_id: value,
                branch_id: this.branch_id,
                dateRange: this.dateRange
            })
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('accounting/journalEntry/RESET_STATE')
        this.$store.commit('accounting/journalEntry/CLEAR_STATE')
        next()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            // vm.fetchData({
            //     branch_id: vm.branch_id,
            //     dateRange: vm.dateRange
            // })
            vm.getTransactionType()
        })
    }
}
</script>
