var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","show-summary":true,"summary-method":_vm.handleSummary,"stripe":""},scopedSlots:_vm._u([{key:"account_number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.account ? row.account.account_number : "")+" ")]}},{key:"account_name",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.account ? row.account.account_name_en : "")+" "+_vm._s(row.account.account_name_kh ? "|" + row.account.account_name_kh : "")+" ")]}},{key:"debit_amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.debit_amount, _vm.currency))+" ")]}},{key:"credit_amount",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode(row.credit_amount, _vm.currency))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }